import { render } from "./index.vue?vue&type=template&id=15a18ad1&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./index.vue?vue&type=style&index=0&id=15a18ad1&lang=scss&module=true"
cssModules["$style"] = style0
import "./index.vue?vue&type=style&index=1&id=15a18ad1&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-15a18ad1"

export default script